<template>
  <div class="content plat-user-member-list-page">
    <!-- 用户界面 -->
    <div v-if="isShow">
  
      <div class="search">
        <!-- <el-input placeholder="用户ID" v-model="user_id" clearable style="width:160px">
        </el-input> -->
        <el-form :inline="true" class="mt10" size="medium">
          <el-form-item>
            <el-input placeholder="会员ID/手机号"
                      v-model="form.tel"
                      clearable style="width:160px"
                      @keyup.enter.native="search">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.state" placeholder="全部状态" clearable style="width:160px">
              <el-option value="0" label='全部状态'>全部状态</el-option>
              <el-option value="1" label='正常'>正常</el-option>
              <el-option value="2" label='禁用'>禁用</el-option>
            </el-select>
          </el-form-item>
           <el-date-picker v-model="addTime" type="daterange" align="right" unlink-panels
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
           class="mr5">
        </el-date-picker>
          <el-form-item>
            <el-button type="primary" @click='search'>搜索</el-button>
             <el-button type="info" @click="clear('clear')">清空</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="import-user">
        <span >
          <!-- <el-button
            type="primary"
            size="small"
            class="import-btn"
            @click="exportMemberData">导出会员</el-button> -->
          <!-- <span class="left mr10" style="line-height: 28px;">
            {{totalVipName}}: {{totalVipNums}}
          </span> -->
<!--         
           <el-button
             type="primary"
             size="small"
             class="import-btn"
             :disabled="!tableData.length"
             @click="exportOrdersV2">导出用户</el-button> -->
          <!-- <el-button
            type="primary"
            size="small"
            class="import-btn"
            @click="lookHistory">查看导出列表</el-button> -->
        </span>
      </div>
      <el-table :data="tableData"
        border
        stripe style="width: 100%">
        <el-table-column prop="createTime" label="注册时间"  :formatter='formatTime'></el-table-column>
        <el-table-column prop="userId" label="UID"  show-overflow-tooltip></el-table-column>
        <el-table-column prop="nickName" label="昵称" show-overflow-tooltip ></el-table-column>
        <el-table-column prop="tel" label="手机号码" align='center' ></el-table-column>
        <el-table-column prop="gender" label="性别" align='center'  :formatter='formatSex'></el-table-column>
       
      
        <!-- <el-table-column prop="money" label="账户余额" align='center' min->
          <template slot-scope="scope">
            <p class='red'>{{scope.row.money}}</p>
          </template>
        </el-table-column> -->
      
    
        <el-table-column prop="state" label="状态" align='center' >
          <template slot-scope="scope">
            <p :class='scope.row.state == "2" ? "red" : "green"'>
              {{scope.row.state == '2' ? '禁用' : '正常'}}
            </p>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" align='center' width='150' fixed="right">
          <template slot-scope="scope">
            <div v-if="LoginUserType === 'admin'">
              <el-button
                  size="mini"
                  type="primary" @click="getSpreadAuthorizedCode(scope.row)">编辑</el-button>
            
              <el-button
                  size="mini"
                  v-if="scope.row.status != 2"
                  type="danger" @click="saveSpreadMemberInfo(scope.row)">禁用</el-button>
              <el-button
                  size="mini"
                  v-if="scope.row.status == 2"
                  type="success" @click="saveSpreadMemberInfo(scope.row)">启用</el-button>
            </div>
            <span v-else></span>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
          background
          :page-count="totalPages"
          :current-page.sync='currentPage'
          @current-change='loadUser'>
        </el-pagination>
    </div>
    <!-- 导入用户界面 -->
   
    <!-- 弹窗 -->
    <el-dialog
      title="授权码验证"
      :visible.sync="codeVisible"
      width="650px"
      class="codeVisible"
      center>
      <p class="mb10">敏感操作需输入平台授权码验证通过后，方可继续操作</p>
      <el-input v-model="modifyData.modify_code" placeholder='请输入平台管理员授权码' type="password">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkSpreadAuthorizedCode">提交验证</el-button>
      </span>
    </el-dialog>
    <!-- 编辑会员对话框 -->
    <!-- 弹窗 -->
    <el-dialog
      title="编辑用户"
      :visible.sync="modifyVisible"
      width="650px"
      class="modifyVisible"
      center>
      <p class="mb10">用户ID：{{modifyData.user_id}}</p>
      <p class="mb10">用户名称：{{user.nick_name}}</p>
<!--      <p class="mb10">用户余额：{{user.money}}-->
<!--        <el-button icon="el-icon-edit" size="mini" title="编辑" type="primary"-->
<!--          @click="balanceShow=true" style="margin-left:10px;"></el-button>-->
<!--      </p>-->
      <div class="modify-div mb10" v-show="balanceShow">
        <p class="mb10">
          <el-select v-model="modifyData.money_type" placeholder="增加" class="vip-select">
            <el-option label="增加" value="4"></el-option>
            <el-option label="扣减" value="5"></el-option>
          </el-select>
          <el-input v-model="modifyData.money" class="inline-input" type="number"></el-input>元
        </p>
        <el-input v-model="modifyData.money_desc" placeholder="备注说明"
          class="remark-input"></el-input>
      </div>
      <p class="mb10">用户积分：{{user.score}}
        <el-button icon="el-icon-edit" size="mini" title="编辑" type="primary"
          @click="scoreShow=true" style="margin-left:10px;"></el-button>
      </p>
      <div class="modify-div mb10" v-show="scoreShow">
        <p class="mb10">
          <el-select v-model="modifyData.score_type" placeholder="增加" class="vip-select">
            <el-option label="增加" value="4"></el-option>
            <el-option label="扣减" value="5"></el-option>
          </el-select>
          <el-input v-model="modifyData.score" class="inline-input" type="number"></el-input>分
        </p>
        <el-input v-model="modifyData.score_desc" placeholder="备注说明"
          class="remark-input"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="spreadSaveVipMoneyOrScore">提交保存</el-button>
      </span>
    </el-dialog>
    <!-- 资金记录对话框 -->
    <!-- 弹窗 -->

    <el-dialog
      :title="'积分明细 - '+ nick_name"
      :visible.sync="showScoreDetailed"
      width="980px"
      class="detail monely-detail-dialog">
      <!-- 搜索框 -->
      <div class="detailSearch mb20 mt20">
        <el-date-picker
          v-model="startTime"
          type="date"
          value-format='yyyy-MM-dd'
          placeholder="开始时间">
        </el-date-picker>
        <el-date-picker
          v-model="endTime"
          type="date"
          value-format='yyyy-MM-dd'
          placeholder="截止时间">
        </el-date-picker>
        <el-select v-model="detailType" placeholder="请选择类型">
          <el-option
            label="全部类型"
            key="0"
            value="0">
          </el-option>
          <el-option
            v-for="opt in typeOptions"
            :key="opt.value"
            :label="opt.label"
            :value="opt.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click='scopeListSearch'>搜索</el-button>
      </div>
      <!-- 分页 -->
      <el-pagination
        background
        :page-count="detailTotalIngetral"
        :current-page.sync='detailScopeCurrentPage'
        @current-change='getScoreList'>
      </el-pagination>
      <!-- 表格 -->
      <el-table :data="detailIntegral" stripe
        style="width: 100%" border>
        <el-table-column prop="create_time" label="时间" width='140'>
          <template slot-scope='scope'>
            <p>{{$formatDate(scope.row.create_time, 'yyyy-MM-dd  HH:mm')}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="desc" label="说明" >
        </el-table-column>
        <el-table-column prop="type" label="类型"
        text-align='center' :formatter='integralType' width='150'>
        </el-table-column>
        <!-- <el-table-column prop="true_money" label="变动金额"
        text-align='center' width='80' align='center'>
          <template slot-scope='scope'>
            <p :class="isred(scope.row.type,scope.row.true_money)">
              {{formatChange(scope.row.type,scope.row.true_money)}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="free_money"
        label="当前余额" align='center' width='100'>
        </el-table-column> -->
        <el-table-column prop="this_score" label="变动积分"
        text-align='center' width='80' align='center'>
          <template slot-scope='scope'>
            <p :class="isred(scope.row.type,scope.row.this_score)">
              {{formatChange(scope.row.type,scope.row.this_score)}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="score"
        label="当前积分" align='center' width='100'>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 引入请求导出的组件 -->
    <!-- <export-tips :comNmae='$options.name' :exportParams='exportParams' ></export-tips> -->
    <!-- <export-shop-list ref="ExportShopListComp"></export-shop-list> -->
  </div>
</template>
<script>
import {
  $sg_getSpreadVipListV2,
  $sp_saveSpreadPlatUser,
  $sg_getSpreadAuthorizedCode,
  $sp_checkSpreadAuthorizedCode,
  $sg_getSpreadPlatUserDetailed,
  $sp_spreadSaveUserMoneyOrScore,
  $sg_getScoreList,
  $sg_getMigrateVipFailList,
  $sg_clearMigrateVipHistroy,
  // $sg_exportUsersV2,
} from '@/api/setting';

import { DEFAULT_DATA_PAGES, getBaseUrl } from '@/config';
// import ExportTips from '@/components/common/ExportTips';
// import store from '@/store';
import moment from 'moment';

export default {
  name: 'PlatuserMemberlist',

  data() {
    return {
      // exportParams: {},
      addTime: null,
      actionsUrl: `${getBaseUrl()}/platform/v1/SpreadUser/migrateSpreadVip`,
      tableData: [],
      totalPages: DEFAULT_DATA_PAGES,
      detailTotalPages: DEFAULT_DATA_PAGES,
      detailTotalIngetral: DEFAULT_DATA_PAGES,
      failTotalPage: DEFAULT_DATA_PAGES,
      currentPage: 1,
      detailCurrentPage: 1,
      detailScopeCurrentPage: 1,
      currentFailPage: 1,
      user_id: '',
      status: '',
      user_tel: '',
      user_type: '',
      nick_name: '',
      startTime: '',
      endTime: '',
      dateRange: [],
      detailType: '',
      detailData: [],
      detailIntegral: [],
      importFailData: [],
      card_id: '',
      isShow: true,
      isShowNum: false,
      codeVisible: false,
      modifyVisible: false,
      scoreShow: false,
      balanceShow: false,
      showScoreDetailed: false,
      showDetailed: false,
      activeName: 'first',
      successNum: 0,
      failNum: 0,
      // handlePreview: '',
      modifyData: {
        user_id: 0, // 必选 int 用户id
        modify_code: '', // 必选 string 平台授权码（平台配置必须传）
        money: 0, // 必选 string 金额
        money_type: '', // 必选 int 4增加，5扣减
        money_desc: '', // 必选 string 加减余额备注
        score: 0, // 必选 int 积分
        score_type: '', // 必选 int 4增加，5扣减
        score_desc: '', // 必选 string 加减积分备注
      },
      user: {},
      scopeType: ['消费赠送积分', '撤单退还积分', '手动添加积分', '手动扣减积分', '结转会员积分', '兑换扣减积分', '完善资料赠送积分', '评价赠送积分', '储值赠送积分', '获得积分', '扣减积分', '注册赠送积分', '邀请赠送积分', '登录赠送积分', '开通会员赠送积分', '收藏店铺赠送积分', '抵现扣减积分', '签到赠送积分', '连签赠送积分', '买单记录退款', '兑换返还积分', '退款返还积分', '抽奖消耗积分', '抽奖获得积分'],
      sort_status: 'desc',
      sort_name: '',
      totalVipNums: 0,
      totalVipName: '',
      form: {
        startTime: null,
        endTime: null,
        state: null,
        tel: null,
        time: null,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '昨天',
            onClick(picker) {
              const end = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
              const start = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          }, {
            text: '最近7天',
            onClick(picker) {
              const end = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
              const start = moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          }, {
            text: '最近30天',
            onClick(picker) {
              const end = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
              const start = moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  components: {
   
 
    // ExportTips,
  },
  computed: {
   
    typeOptions() {
      return this.scopeType.map((item, index) => ({
        label: item,
        value: index + 1,
      }));
    },
  },
  methods: {
    // 显示积分明细模块框
    showScoreDetail(row) {
      // console.log(row);
      this.showScoreDetailed = true;
      // 普通用户card_id 传user_id
      this.user_id = row.user_id;
      this.nick_name = row.nick_name;
      this.getScoreList();
    },
    // 积分变动明细
    getScoreList() {
      const params = {
        start_time: this.startTime,
        end_time: this.endTime,
        user_id: this.user_id,
        type: this.detailType,
        page: this.detailScopeCurrentPage,
        size: 10,
      };
      // console.log(params);
      if (this.userType === '0') {
        this.detailIntegral = [];
        return; //eslint-disable-line
      }
      $sg_getScoreList(params).then((resp) => {
        // console.log('积分明细列表:', resp);
        this.detailIntegral = resp.data;
        this.detailTotalIngetral = resp.count;
      });
    },
    // 状态切换
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 导入平台会员失败记录
    importFaliRecord() {
      const params = {
        page: this.currentFailPage,
        size: 10,
      };
      $sg_getMigrateVipFailList(params).then((resp) => {
        this.importFailData = resp.data;
        this.failTotalPage = parseInt(resp.count, 10);
      });
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    // 加载成功
    uploadSuccess(resp) {
      // console.log('加载成功:', resp.data);
      this.importFaliRecord();
      this.successNum = resp.data.success;
      this.failNum = resp.data.fail;
      this.isShowNum = true;
      this.$refs.upload.clearFiles();
    },
    fileOnExceed() {
      this.$message('每次只能上传一个文件，请先将此文件上传之后再行上传！');
    },
    // 清除失败记录
    clearFailRecord() {
      $sg_clearMigrateVipHistroy().then((resp) => {
        console.log('清除失败记录:', resp);
        this.importFaliRecord();
        this.successNum = 0;
        this.failNum = 0;
      });
    },
    // 切换显示页面页面
    importVipUsers() {
      this.isShow = !this.isShow;
      if (!this.isShow) this.importFaliRecord();
    },
    // 点击搜索
    search() {
      this.currentPage = 1;
      this.loadUser();
    },
    // 修改平台会员卡的状态
    async saveSpreadMemberInfo(row) {
      await this.$confirm('是否改变状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      let status = '1';
      if (row.status == '1') { /* eslint-disable-line*/
        status = '2';
      }
      const params = {
        user_id: row.user_id,
        status,
      };
      $sp_saveSpreadPlatUser(params).then(() => {
        this.loadUser();
      });
    },
    clear(param) {
      this.form = {
        startTime: null,
        endTime: null,
        state: null,
        tel: null,
        time: null,
      };
      this.loadUser(param);
    },
    // 加载用户数据
    loadUser(param) {
      let params;
      if (param === 'clear') {
        this.addTime = null;
        params = {
          pageCurrent: 1,
          pageSize: 10,
        };
      } else {
        params = {
          pageCurrent: this.currentPage,
          pageSize: 10,
          state: this.form.state,
          tel: this.form.tel,
          createTimeStart: this.addTime && this.addTime.length > 0 ? `${this.$formatDateByDate(this.addTime[0], 'yyyy-MM-dd')}` : '',
          createTimeEnd: this.addTime && this.addTime.length > 0 ? `${this.$formatDateByDate(this.addTime[1], 'yyyy-MM-dd')}` : '',
        };
      }
     
      if (this.sort_name) {
        params.sort_by = `${this.sort_name},${this.sort_status}`;
      }
      $sg_getSpreadVipListV2(params).then((res) => {
        console.log(res);
        this.tableData = res.records;
        this.totalPages = res.pages;
        this.totalVipNums = res.totalVipNums;
        let text = '平台注册总会员数';
        switch (this.user_type) {
          case '1':
            text = '平台注册普通会员数';
            break;
          case '2':
            text = '平台注册充值会员数';
            break;
          default: break;
        }
        this.totalVipName = text;
      });
    },
    showPager() {
      this.loadUser();
    },
    currentChange(val) {
      this.currentPage = val;
      this.loadUser();
    },
    // 显示明细模块框
    showDetailModel(row) {
      this.startTime = '';
      this.endTime = '';
      this.detailType = '';
      this.detailCurrentPage = 1;
      this.showDetailed = true;
      this.card_id = row.card_id;
      this.nick_name = row.nick_name;
      this.getDetail();
      this.getScoreList();
    },
    // 明细搜索
    searchDetail() {
      this.detailCurrentPage = 1;
      this.getDetail();
    },
    scopeListSearch() {
      this.detailScopeCurrentPage = 1;
      this.getScoreList();
    },
    // 判断平台是否设置授权码
    getSpreadAuthorizedCode(row) {
      this.modifyData.user_id = row.user_id;
      this.modifyData.modify_code = '';
      this.scoreShow = false;
      this.balanceShow = false;
      this.user = {
        money: row.money,
        score: row.score,
        nick_name: row.nick_name,
      };
      $sg_getSpreadAuthorizedCode().then((res) => {
        if (res) this.codeVisible = true;
        else this.modifyVisible = true;
      });
    },
    // 验证授权码
    checkSpreadAuthorizedCode() {
      $sp_checkSpreadAuthorizedCode({ modify_code: this.modifyData.modify_code }).then((res) => {
        if (res) {
          this.codeVisible = false;
          this.$message('验证成功');
          setTimeout(() => {
            this.modifyVisible = true;
          }, 500);
        }
      });
    },
    // 获取消费明细
    getDetail() {
      const params = {
        start_time: this.startTime,
        end_time: this.endTime,
        card_id: this.card_id,
        type: this.detailType,
        page: this.detailCurrentPage,
        size: 10,
      };
      if (this.userType === '0') {
        this.detailData = [];
        return; //eslint-disable-line
      }
      $sg_getSpreadPlatUserDetailed(params).then((res) => {
        this.detailData = res.data;
        this.detailTotalPages = res.count;
      });
    },
    spreadSaveVipMoneyOrScore() {
      const params = { ...this.modifyData };
      if (Number(params.money) < 0 || Number(params.score) < 0) {
        this.$message.error('请输入大于0的数字');
        return;
      }
      if (!params.money_type) params.money_type = 4;
      if (!params.score_type) params.score_type = 4;
      $sp_spreadSaveUserMoneyOrScore(params).then(() => {
        this.loadUser();
        this.modifyVisible = false;
        this.modifyData = {
          user_id: 0,
          modify_code: '',
          money: 0,
          money_type: '',
          money_desc: '',
          score: 0,
          score_type: '',
          score_desc: '',
        };
      });
    },
    renderSort(h) {
      return h(
        'p',
        [
          h('span', '下单次数'),
          h('el-button', {
            attrs: {
              circle: true,
              icon: 'el-icon-d-caret',
              style: 'padding: 0;font-size: 16px;margin-left: 3px;border:0;',
            },
            on: {
              click: () => {
                this.sortChange();
              },
            },
          }),
        ],
      );
    },
    renderSortBuy(h) {
      return h(
        'p',
        [
          h('span', '累计消费金额'),
          h('el-button', {
            attrs: {
              circle: true,
              icon: 'el-icon-d-caret',
              style: 'padding: 0;font-size: 16px;margin-left: 3px;border:0;',
            },
            on: {
              click: () => {
                this.sortChangeBuy();
              },
            },
          }),
        ],
      );
    },
    sortChange() {
      if (this.sort_name === 'order_count') {
        this.sort_status = this.sort_status === 'desc' ? 'asc' : 'desc';
      } else {
        this.sort_name = 'order_count';
        this.sort_status = 'desc';
      }
      this.loadUser();
    },
    sortChangeBuy() {
      if (this.sort_name === 'order_money') {
        this.sort_status = this.sort_status === 'desc' ? 'asc' : 'desc';
      } else {
        this.sort_name = 'order_money';
        this.sort_status = 'desc';
      }
      this.loadUser();
    },
    formatChange(type, value) {
      // 1.会员消费+，2.会员退款-，3.平台加积分+，4.平台减积分-, 5.结转会员积分+，6.会员兑换积分商品-，7.完善会员资料+，8.完成订单评价+，9.会员充值送积分
      // 1-会员消费，2.会员退款，3-平台加积分，4.平台减积分，5.结转会员积分，6.会员兑换积分商品，7.完善会员资料，8.完成订单评价，9会员充值送积分, 10获得积分, 11扣减积分, 12注册赠送积分, 13邀请赠送积分, 14登录赠送积分,15开通会员赠送积分, 16收藏店铺赠积分，17下单积分抵扣扣减, 18每日签到赠积分，19连续签到赠积分
      if (value === 0) return value;
      if (type === 2 && value < 0) return `+${Math.abs(value)}`;
      if (type === 4 || type === 6 || type === 17 || type === 23) {
        return `-${value}`;
      }
      return `+${value}`;
    },
    isred(type, value) {
      if (value === 0 || (type === 2 && value < 0)) return 'green';
      if (type === 4 || type === 6 || type === 17 || type === 23) return 'red';
      return 'green';
    },
    formatType({ type }) {
      return this.$getDescFromCode(type, 'accountType');
      // const typeText = ['会员在线充值', '下单扣减储值', '撤单返还储值', '手动增加储值', '手动扣减储值', '结转会员余额'];
      // return typeText[type - 1];
    },
    integralType({ type }) {
      // const typeText = this.scopeType;
      // return typeText[type - 1];
      return this.$getDescFromCode(type, 'integralType');
    },
    formatTime({ createTime }) {
      return this.$formatDate(createTime, 'yyyy-MM-dd HH:mm');
    },
    formatTime1({ lastLoginTime }) {
      return this.$formatDate(lastLoginTime, 'yyyy-MM-dd HH:mm');
    },
    formatSex({ gender }) {
      let sex;
      // eslint-disable-next-line eqeqeq
      if (gender == 1) sex = '未知';
      // eslint-disable-next-line eqeqeq
      else if (gender == 2) sex = '男';
      else sex = '女';
      return sex;
    },
    formatTime2({ latestTime }) {
      return this.$formatDate(latestTime, 'yy-MM-dd HH:mm');
    },
    exportOrdersV2() {
      const exportParams = {
        user_id: this.user_id,
        status: this.status,
        tel: this.user_tel,
        user_type: this.user_type,
        sort_by: '',
        export: 1,
      };
      if (this.sort_name) {
        exportParams.sort_by = `${this.sort_name},${this.sort_status}`;
      }
      console.log(this.$options.name);
      const options = {
        exportParams,
        comName: this.$options.name,
      };
      this.$exportTips.open(options);
      // $sg_exportUsersV2(params).then((res) => {
      //   if (res.left_export_num > 0) {
      //     const message = `本次导出${res.export_num}条，预计导出时间为${res.export_time}秒；您的数据导出申请，将会被添加的导出任务列表中，当导出完成后即可直接点击下载，您是否确认需要导出数据？`;
      //     this.$confirm(message, '', { center: true }).then(() => {
      //       params.export = 1;
      //       $sg_exportUsersV2(params);
      //       this.lookHistory();
      //     }).catch(() => {});
      //   } else {
      //     const errorMsg = `每日可导出数据总条数限制为 ${res.export_limit} 条，您已超出今日导出限制，请明天再使用导出功能！`;
      //     this.$alert(errorMsg, { confirmButtonText: '知道啦', center: true });
      //   }
      // });
    },
    // 查看导出列表
    lookHistory() {
      this.$refs.ExportShopListComp.open();
    },
  },
  created() {
    console.log(this.LoginUserType, 'LoginUserType');
    this.loadUser();
  },
};

</script>
<style lang="scss">
.plat-user-member-list-page{
  .content {
    width: 100%;
    height: 100%;
    background: #fff;
  }
  // 资金记录弹窗
  .monely-detail-dialog {
    .el-dialog__header{
      border-bottom: 1px solid rgb(187, 187, 187);
    }
    .el-dialog__body {
      padding-top: 0;
      .el-tabs__nav-wrap::after {
        height: 0;
      }
    }
  }
  .el-tabs__nav-scroll{
    display: flex;
    justify-content: center;
    margin-bottom: 4px;
  }
  .import-user{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4px;
    .import-btn{
      // height: 28px;
      margin-right: 10px;
    }
  }
  .search {
    margin: 14px 0;
    padding-left: 10px;
  }
  .codeVisible{
    .el-dialog__body {
      padding-left: 30px;
    }
  }
  .detail{
    .el-dialog{
      margin-top: 7vh !important;
    }
  }
  .modify-div{
    background-color: #F2F2F2;
    padding: 10px;
    .inline-input{
      width: 40%;
      margin: 0  10px;
    }
    .remark-input{
      width: 96%;
    }
    .vip-select{
      width:100px;
    }
  }
  .import-vip-users{
    .import-normal{
      display: flex;
      flex-direction: row;
      padding:10px;
      box-sizing: border-box;
      h4{
        margin: 0;
        font-weight: 700;
        cursor: pointer;
      }
    }
    .line-cross{
      width: 100%;
      height: 1px;
      background-color: #ccc;
    }
    .import-notice{
      padding: 0 10px;
      box-sizing: border-box;
      line-height: 30px;
      font-weight: 400;
      font-size: 14px;
      color: rgb(16, 16, 16);
      a{
        text-decoration: underline;
        color: rgba(1, 134, 146, 1);
      }
    }
    .choose-file{
      padding: 10px;
      button{
        height: 28px;
      }
    }
    .import-text{
      width: 800px;
      height: 40px;
      line-height: 40px;
      background-color: rgb(229, 229, 229);
      padding-left:6px;
      margin-left: 10px;
      margin-bottom: 12px;
      span{
        font-weight: 700;
        font-size: 16px;
      }
      .success-num{
        color: rgba(37, 155, 36, 1);
      }
      .fail-num{
        color: rgb(229, 28, 35);
      }
    }
    .page-btn{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 20px;
      box-sizing: border-box;
      margin-bottom: 8px;
      // button{
      //   height: 28px;
      // }
    }
  }
}
</style>
